import React, { useRef, useEffect, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import useOnClickOutside from 'use-onclickoutside';
import styled from '@emotion/styled';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { FiX } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useTransition, animated } from 'react-spring';
import hexToRgba from 'hex-to-rgba';
import Link from '../Link';
import IconButton from '../IconButton';
import { SYSTEM_FONTS } from '../../styles/typography';
import getZIndex from '../../styles/zIndex';
import logo from '../../assets/images/logo-inverted.svg';
import {
  ContentfulCategoryEdge,
  ContentfulHeaderLinks,
  ContentfulHeaderLinksConnection,
} from '../../graphql-types';
import { ReactComponent as PhoneIcon } from '../../assets/svgs/header/phone.svg';
import { ButtonLink } from '../common/Button';
import { REZEPT_URL } from '../../utils/constants';
import LanguageSelect from './LanguageSelect';
import { css } from '@emotion/core';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  categories: ContentfulCategoryEdge[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  aboutPages: any[];
  hideCallToAction?: boolean;
}

const EmphasizedLink = styled(Link)({
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  fontFamily: ['Source Code Pro'].concat(SYSTEM_FONTS).join(', '),
  fontWeight: 600,
  fontSize: '1.125rem',
  color: '#fff',
  lineHeight: '24px',
});

const MobileMenuLink = styled(Link)({
  width: '100%',
  fontFamily: ['Source Code Pro'].concat(SYSTEM_FONTS).join(', '),
  fontWeight: 600,
  textTransform: 'uppercase',
  color: '#fff',
  height: 50,
  borderTop: '1px solid rgba(255, 255, 255, 0.2)',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  fontSize: '0.875rem',
  lineHeight: '24px',
  transition: 'background-color 0.15s cubic-bezier(0.4, 0.1, 1, 1)',
  '&:hover': {
    backgroundColor: hexToRgba('#fff', 0.1),
    textDecoration: 'none',
  },

  '&:last-of-type': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
  },

  '&.active': {
    backgroundColor: hexToRgba('#fff', 0.1),
    textDecoration: 'none',
  },
});

const SubMobileMenuLink = styled(Link)({
  paddingLeft: '3rem',
  width: '100%',
  fontFamily: ['Source Code Pro'].concat(SYSTEM_FONTS).join(', '),
  fontWeight: 600,
  textTransform: 'uppercase',
  color: '#fff',
  height: 50,
  borderTop: '1px solid rgba(255, 255, 255, 0.2)',
  display: 'flex',
  alignItems: 'center',
  paddingRight: '1rem',
  fontSize: '0.875rem',
  lineHeight: '24px',
  transition: 'background-color 0.15s cubic-bezier(0.4, 0.1, 1, 1)',
  '&:hover': {
    backgroundColor: hexToRgba('#fff', 0.1),
    textDecoration: 'none',
  },

  '&:last-of-type': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
  },

  '&.active': {
    backgroundColor: hexToRgba('#fff', 0.1),
    textDecoration: 'none',
  },
});

const MobileMenu: React.FC<MobileMenuProps> = ({
  isOpen,
  onClose,
  categories,
  aboutPages,
  hideCallToAction,
}) => {
  const sidebarRef = useRef(null);
  const intl = useIntl();
  // TODO: Use hook to query the links once some are added in contentful:
  const emphasizedLinks: ContentfulHeaderLinks | null = null; //useHeaderLinks();
  const { allContentfulHeaderLinks } = useStaticQuery<{
    allContentfulHeaderLinks: ContentfulHeaderLinksConnection;
  }>(graphql`
    {
      allContentfulHeaderLinks {
        nodes {
          node_locale
          mobileLinks {
            id
            text
            url
            isDisplayed
          }
        }
      }
    }
  `);
  const localizedLinks = allContentfulHeaderLinks.nodes.find(
    (node) => node.node_locale === intl.locale
  );
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0, transform: 'scale(0.9) translateZ(-1px)' },
    enter: { opacity: 1, transform: 'scale(1) translateZ(1px)' },
    leave: { opacity: 0, transform: 'scale(0.9) translateZ(-1px)' },
  });
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useOnClickOutside(sidebarRef, onClose);

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(sidebarRef.current);
    } else {
      enableBodyScroll(sidebarRef.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen]);

  useEffect(() => {
    const isClinet = typeof window !== 'undefined';
    if (isClinet) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const containerStyle = css`
    padding: 1rem;
    margin-left: auto;
  `;

  const headerTextStyle = css`
    font-family: 'Source Code Pro', ${SYSTEM_FONTS};
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    line-height: 24px;
    margin-bottom: 1rem;
  `;

  const normalTextStyle = css`
    fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
    font-weight: 400;
    font-size: 0.875rem;
    color: #fff;
    line-height: 24px;

    &:hover {
      text-decoration: none;
    }
  `;

  const IconStyles = css`
    margin-right: 0.5rem;
    fill: #fff;
  `;

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              ref={sidebarRef}
              css={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                height: '100vh',
                paddingTop: 32,
                paddingBottom: 20,
                flexDirection: 'column',
                backgroundColor: '#489f9d',
                opacity: 0,
                willChange: 'transform, opacity',
                overflowY: 'auto',
                zIndex: getZIndex('sidebar'),
              }}
              style={props}
            >
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: 48,
                }}
              >
                <Link
                  to="/"
                  aria-label="Cara Care homepage"
                  css={{
                    paddingLeft: 32,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                  onClick={onClose}
                >
                  <img
                    src={logo}
                    alt="Cara Care logo"
                    aria-hidden="true"
                    css={{
                      margin: 0,
                      width: 130,
                      height: 'auto',
                    }}
                  />
                </Link>
                <IconButton
                  role="button"
                  title="close navigation"
                  aria-label="close navigation"
                  onClick={onClose}
                  css={{
                    marginRight: 32,
                    padding: 0,
                    backgroundColor: 'transparent',
                    border: 0,
                    outline: 0,
                    overflow: 'hidden',
                    cursor: 'pointer',
                  }}
                >
                  <FiX size={24} color="#fff" />
                </IconButton>
              </div>
              <div
                css={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '1rem',
                  }}
                >
                  {emphasizedLinks?.links.map((link) => (
                    <EmphasizedLink
                      key={link.id}
                      to={link.url}
                      onClick={onClose}
                    >
                      {link.text}
                    </EmphasizedLink>
                  ))}
                </div>
                {hideCallToAction ? null : (
                  <ButtonLink
                    css={{
                      marginBottom: '1rem',
                      margin: '1rem',
                      backgroundColor: '#fff',
                      color: '#489f9d',
                    }}
                    to={REZEPT_URL}
                    design="bordered"
                  >
                    {intl.formatMessage({
                      id: 'prescription.service.code',
                      defaultMessage: 'REZEPTSERVICE',
                    })}
                  </ButtonLink>
                )}

                {localizedLinks.mobileLinks
                  .filter((link) => link.isDisplayed)
                  .map((link) => (
                    <MobileMenuLink
                      key={link.id}
                      to={link.url}
                      onClick={onClose}
                    >
                      {link.text}
                    </MobileMenuLink>
                  ))}
                <MobileMenuLink
                  css={{
                    cursor: 'default',
                  }}
                  to="#"
                  onClick={onClose}
                >
                  {intl.formatMessage({
                    id: 'nav.header.lexicon',
                    defaultMessage: 'Lexikon',
                  })}
                </MobileMenuLink>

                {categories
                  .filter((category) => category.node.slug !== 'news')
                  .map((category) => (
                    <SubMobileMenuLink
                      key={category.node.id}
                      to={`/${category.node.slug}`}
                      onClick={onClose}
                    >
                      {category.node.title}
                    </SubMobileMenuLink>
                  ))}

                <MobileMenuLink
                  css={{
                    cursor: 'default',
                  }}
                  to="#"
                  onClick={onClose}
                >
                  {intl.formatMessage({
                    id: 'nav.header.about.us.title',
                    defaultMessage: 'Über uns',
                  })}
                </MobileMenuLink>

                {aboutPages?.map((page) => (
                  <SubMobileMenuLink
                    key={page.id}
                    to={page.url}
                    onClick={onClose}
                  >
                    {page.title}
                  </SubMobileMenuLink>
                ))}

                <div css={containerStyle}>
                  <p css={headerTextStyle}>SERVICE-HOTLINE:</p>
                  <div css={{ display: 'flex', alignItems: 'center' }}>
                    <PhoneIcon css={IconStyles} />
                    <span
                      css={[
                        normalTextStyle,
                        {
                          fontWeight: 600,
                        },
                      ]}
                    >
                      030 3080 9546
                    </span>
                  </div>
                  <p css={normalTextStyle}>
                    {intl.formatMessage({
                      id: 'header.hours',
                      defaultMessage: '(Mo–Fr 9–18 Uhr)',
                    })}
                  </p>
                  <p css={headerTextStyle}>
                    {intl.formatMessage({
                      id: 'header.language',
                      defaultMessage: 'SPRACHE:',
                    })}
                  </p>
                  <LanguageSelect isDarkHeader={false} isMobileMenu />
                </div>
              </div>
            </animated.div>
          )
      )}
    </>
  );
};

export default MobileMenu;
